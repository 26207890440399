<template>
   <div id="notification" :class="fade_class" v-if="show">
      {{ message }}
   </div>
</template>

<script>
export default {
   data: () => {
      return {
         show: false,
         fade: false,
         message: "Hola a todos",
         color: "",
      };
   },
   methods: {
      Show(message) {
         this.show = true;
         this.fade = true;
         this.message = message;
         setTimeout(() => {
            this.fade = false;
         }, 3000);
         setTimeout(() => {
            this.show = false;
         }, 3300);
      },
   },
   computed: {
      fade_class: function () {
         if (this.fade) {
            return "fade";
         } else {
            return "";
         }
      },
   },
};
</script>

<style scoped>
#notification {
   position: fixed;
   right: 20px;
   top: 20px;
   padding: 16px;
   z-index: 100000;
   background-color: rgb(243, 74, 74);
   color: white;
   font-family: "roboto";
   border-radius: 5px;
   opacity: 0;
   transition: opacity 300ms ease-in-out;
}

.fade {
   opacity: 1 !important;
   transition: all 300ms ease-in-out;
}
</style>
