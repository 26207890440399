import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import c from "./constant";

Vue.use(Vuex);
axios.defaults.baseURL = c.API_URL;
axios.defaults.headers.common["Cache-Control"] = "no-cache";
axios.defaults.headers.common["Pragma"] = "no-cache";
axios.defaults.headers.common["Expires"] = "0";

const getCacheableState = () => {
   return {
      username: "",
      name: "",
      token: "",
      permisos: [],
   };
};
const getDefaultState = () => {
   return {
      userdata: getCacheableState(),
      users: [],
      settings: [],
      roles: [],
      permisos: [],
      sucursales: [],
      comisiones: [],
      subcuentas: [],
      cuentas: {
         items: [],
         totalItems: 10,
         options: {},
      },
      movimientos: {
         items: [],
         totalItems: 50,
         options: {},
      },
      bitacora: {
         items: [],
         totalItems: 10,
         options: {},
      },
      fondos_maximos: {},
      comision: {},
   };
};

export default new Vuex.Store({
   state: getDefaultState(),
   mutations: {
      initializeStore(state) {
         if (localStorage.getItem("userdata")) {
            let userdata = JSON.parse(localStorage.getItem("userdata"));
            state.userdata = userdata;
            axios.defaults.headers.common["Authorization"] = "Bearer " + state.userdata.token;
         }
      },
      logout() {
         this.replaceState(getDefaultState());
      },
      login(state, payload) {
         state.userdata = payload;
      },
      sucursales(state, payload) {
         state.sucursales = payload;
      },
      users(state, payload) {
         state.users = payload;
      },
      settings(state, payload) {
         state.settings = payload;
      },
      roles(state, payload) {
         state.roles = payload;
      },
      comisiones(state, payload) {
         state.comisiones = payload;
      },
      bitacora(state, payload) {
         state.bitacora = payload;
      },
      movimientos(state, payload) {
         state.movimientos = payload;
      },
      cuentas(state, payload) {
         state.cuentas = payload;
      },
      subcuentas(state, payload) {
         state.subcuentas = payload;
      },
      fondos_maximos(state, payload) {
         state.fondos_maximos = payload;
      },
      comision(state, payload) {
         state.comision = payload;
      },
      permisos(state, payload) {
         let permisos = Object.entries(payload);
         state.permisos = [];
         permisos.forEach(function (val) {
            state.permisos.push({
               id_permiso: val[1],
               permiso: val[0],
            });
         });
      },
   },
   actions: {
      async login(context, payload) {
         try {
            let res = await axios.post("auth", {
               username: payload.username,
               password: payload.password,
            });
            context.commit("login", res.data.data);
            axios.defaults.headers.common["Authorization"] = "Bearer " + context.state.userdata.token;
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      //GET
      async getUsers(context) {
         try {
            let res = await axios.get("user");
            context.commit("users", res.data.data);
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      async getConfig(context) {
         try {
            let res = await axios.get("settings");
            context.commit("settings", res.data.data);
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      async getSucursales(context) {
         try {
            let res = await axios.get("branch");
            context.commit("sucursales", res.data.data);
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      async getRoles(context) {
         try {
            let res = await axios.get("roles");
            context.commit("roles", res.data.data);
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      async getPermisos(context) {
         try {
            let res = await axios.get("roles/permissions");
            context.commit("permisos", res.data.data);
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      async getComisiones(context) {
         try {
            let res = await axios.get("comissions");
            context.commit("comisiones", res.data.data);
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      async getBitacora(context, payload) {
         try {
            if (!payload) {
               payload = context.state.bitacora.options;
            }
            let res = await axios.get("log", {
               params: payload,
            });
            context.commit("bitacora", {
               items: res.data.data.items,
               totalItems: res.data.data.totalItems,
               options: payload,
            });
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      async getMovimientos(context, payload) {
         try {
            if (!payload) {
               payload = context.state.movimientos.options;
            }
            let res = await axios.get("movements", {
               params: payload,
            });
            context.commit("movimientos", {
               items: res.data.data.items,
               totalItems: res.data.data.totalItems,
               options: payload,
            });
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      async getCuentas(context, payload) {
         try {
            if (!payload) {
               payload = context.state.cuentas.options;
            }
            let res = await axios.get("account", {
               params: payload,
            });
            context.commit("cuentas", {
               items: res.data.data.items,
               totalItems: res.data.data.totalItems,
               options: payload,
            });
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      async getSubcuentas(context, payload) {
         try {
            let res = await axios.get("subaccount", {
               params: payload,
            });
            context.commit("subcuentas", res.data.data);
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      async getFondosMaximos(context, payload) {
         try {
            let res = await axios.get("account/info", {
               params: payload,
            });
            context.commit("fondos_maximos", res.data.data);
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      async getComisionCargos(context, payload) {
         try {
            let res = await axios.get("charge/amount", {
               params: payload,
            });
            context.commit("comision", res.data.data);
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      async getComisionAbonos(context, payload) {
         try {
            let res = await axios.get("deposit/amount", {
               params: payload,
            });
            context.commit("comision", res.data.data);
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      async exportMovimientos(context, payload) {
         try {
            let res = await axios.get("movements/export", {
               params: payload,
               responseType: "blob",
            });
            return res;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      async exportCuentas(context, payload) {
         try {
            let res = await axios.get("account/export", {
               responseType: "blob",
            });
            return res;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      //INSERT
      async insertRol(context, payload) {
         try {
            await axios.post("roles", {
               id_rol: 0,
               nombre: payload.nombre,
               permisos: payload.permisos,
            });
            context.dispatch("getRoles");
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      async insertSucursal(context, payload) {
         try {
            await axios.post("branch", {
               nombre: payload.nombre,
            });
            context.dispatch("getSucursales");
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      async insertUser(context, payload) {
         try {
            await axios.post("user", payload);
            context.dispatch("getUsers");
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      async insertComision(context, payload) {
         try {
            await axios.post("comissions", payload);
            context.dispatch("getComisiones");
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      async insertCuenta(context, payload) {
         try {
            await axios.post("account", payload);
            context.dispatch("getCuentas");
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      async insertSubcuenta(context, payload) {
         try {
            await axios.post("subaccount", payload);
            context.dispatch("getSubcuentas", { cuenta_id: payload.cuenta_id });
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      async abonoCuenta(context, payload) {
         try {
            let res = await axios.post("deposit", payload);
            return res;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      async cargoSubcuenta(context, payload) {
         try {
            let res = await axios.post("charge/sub", payload);
            return res;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      async cargoCuenta(context, payload) {
         try {
            let res = await axios.post("charge", payload);
            return res;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      async cancelMovimiento(context, payload) {
         try {
            await axios.post("movements/cancel", payload);
            context.dispatch("getMovimientos", null);
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      //UPDATE
      async updateRol(context, payload) {
         try {
            await axios.put("roles", {
               rol_id: payload.rol_id,
               nombre: payload.nombre,
               permisos: payload.permisos,
            });
            context.dispatch("getRoles");
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      async updateSucursal(context, payload) {
         try {
            await axios.put("branch", {
               sucursal_id: payload.sucursal_id,
               nombre: payload.nombre,
            });
            context.dispatch("getSucursales");
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      async updateUser(context, payload) {
         try {
            await axios.put("user", payload);
            context.dispatch("getUsers");
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      async updateComision(context, payload) {
         try {
            await axios.put("comissions", payload);
            context.dispatch("getComisiones");
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      async updateCuenta(context, payload) {
         try {
            await axios.put("account", payload);
            context.dispatch("getCuentas");
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      async updateSubcuenta(context, payload) {
         try {
            await axios.put("subaccount", payload);
            context.dispatch("getSubcuentas", { cuenta_id: payload.cuenta_id });
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      async nipCuenta(context, payload) {
         try {
            await axios.put("account/nip", payload);
            context.dispatch("getCuentas");
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      async nipSubcuenta(context, payload) {
         try {
            await axios.put("subaccount/nip", payload);
            context.dispatch("getSubcuentas", { cuenta_id: payload.cuenta_id });
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      async saveConfig(context, payload) {
         try {
            await axios.put("settings", payload);
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      //DELETE
      async deleteRol(context, payload) {
         try {
            await axios.delete("roles", {
               data: {
                  rol_id: payload.rol_id,
               },
            });
            context.dispatch("getRoles");
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      async deleteUser(context, payload) {
         try {
            await axios.delete("user", {
               data: {
                  usuario_id: payload.usuario_id,
               },
            });
            context.dispatch("getUsers");
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      async deleteSucursal(context, payload) {
         try {
            await axios.delete("branch", {
               data: {
                  sucursal_id: payload.sucursal_id,
               },
            });
            context.dispatch("getSucursales");
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      async deleteComision(context, payload) {
         try {
            await axios.delete("comissions", {
               data: {
                  comision_id: payload.comision_id,
               },
            });
            context.dispatch("getComisiones");
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      async deleteCuenta(context, payload) {
         try {
            let res = await axios.delete("account", {
               data: {
                  cuenta_id: payload.cuenta_id,
               },
            });
            context.dispatch("getCuentas");
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      async deleteSubcuenta(context, payload) {
         try {
            await axios.delete("subaccount", {
               data: {
                  subcuenta_id: payload.subcuenta_id,
               },
            });
            context.dispatch("getSubcuentas", { cuenta_id: payload.cuenta_id });
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
   },
   modules: {},
});
