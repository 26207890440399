module.exports = Object.freeze({
   //API_URL: "http://192.168.1.69/api-madsa/public/",
   API_URL: "php/",
   FRONTEND_URL: "madsagas.mx",
   APP_NAME: "MADSA BANK",
   DEVELOP_MODE: true,
   PERMISOS: {
      super: "super",
      user: "user",
      consultar_usuarios: "consultar_usuarios",
      insertar_usuarios: "insertar_usuarios",
      actualizar_usuarios: "actualizar_usuarios",
      eliminar_usuarios: "eliminar_usuarios",
      consultar_cuentas: "consultar_cuentas",
      insertar_cuentas: "insertar_cuentas",
      actualizar_cuentas: "actualizar_cuentas",
      eliminar_cuentas: "eliminar_cuentas",
      actualizar_nip_de_cuenta: "actualizar_nip_de_cuenta",
      consultar_sucursales: "consultar_sucursales",
      insertar_sucursales: "insertar_sucursales",
      actualizar_sucursales: "actualizar_sucursales",
      eliminar_sucursales: "eliminar_sucursales",
      consultar_roles: "consultar_roles",
      insertar_roles: "insertar_roles",
      actualizar_roles: "actualizar_roles",
      eliminar_roles: "eliminar_roles",
      consultar_comisiones: "consultar_comisiones",
      insertar_comisiones: "insertar_comisiones",
      actualizar_comisiones: "actualizar_comisiones",
      eliminar_comisiones: "eliminar_comisiones",
      consultar_subcuentas: "consultar_subcuentas",
      insertar_subcuentas: "insertar_subcuentas",
      actualizar_subcuentas: "actualizar_subcuentas",
      eliminar_subcuentas: "eliminar_subcuentas",
      actualizar_nip_de_subcuentas: "actualizar_nip_de_subcuentas",
      consultar_bitacora: "consultar_bitacora",
      consultar_movimientos: "consultar_movimientos",
      cancelar_movimientos: "cancelar_movimientos",
      crear_abonos: "crear_abonos",
      crear_cargos: "crear_cargos",
      configuraciones: "configuraciones",
      exportar_movimientos: "exportar_movimientos",
      exportar_cuentas: "exportar_cuentas",
   },
});