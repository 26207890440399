<template>
   <div>
      <v-app-bar app>
         <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
         <v-spacer></v-spacer>
         <v-app-bar-title>
            <v-btn elevation="0" @click="$router.push({ name: 'Dashboard' }, () => {})">
               {{ app_name }}
            </v-btn>
         </v-app-bar-title>
         <v-spacer></v-spacer>
         <div v-if="is_logged">
            <v-btn icon @click="$store.commit('logout'); $router.push({ name: 'Login' }, () => {})">
               <v-icon>mdi-exit-to-app</v-icon>
            </v-btn>
         </div>
         <div v-else>
            <v-btn icon @click="$router.push({ name: 'Login' }, () => {})">
               <v-icon>mdi-account-circle</v-icon>
            </v-btn>
         </div>
      </v-app-bar>
      <v-navigation-drawer v-model="drawer" fixed temporary>
         <template v-slot:prepend>
            <v-list-item two-line>
               <v-list-item-avatar>
                  <v-icon large>mdi-account-circle</v-icon>
               </v-list-item-avatar>
               <v-list-item-content>
                  <v-list-item-title>{{ $store.state.userdata.name }}</v-list-item-title>
               </v-list-item-content>
            </v-list-item>
         </template>
         <v-divider></v-divider>
         <v-list nav dense>
            <v-list-item-group v-model="group" active-class="blue--text text--accent-4">
               <div v-for="(item, index) in $router.options.routes" :key="index">
                  <v-list-item v-if="item.meta.show" @click="$router.push({ name: item.name }, () => {})">
                     <v-list-item-icon><v-icon dense>{{ item.meta.icon }}</v-icon></v-list-item-icon>
                     <v-list-item-title>{{ item.meta.title }}</v-list-item-title>
                  </v-list-item>
               </div>
            </v-list-item-group>
         </v-list>
      </v-navigation-drawer>
   </div>
</template>

<script>
import c from "../constant";

export default {
   computed: {
      app_name: function () {
         return c.APP_NAME;
      },
      is_logged: function () {
         if(this.$store.state.userdata.token){
            return this.$store.state.userdata.token.length > 0;
         }
         return false;
      },
   },
   data: () => ({
      drawer: false,
      group: null,
   }),
   watch: {
      group() {
         this.drawer = false;
      },
   },
};
</script>

<style></style>
