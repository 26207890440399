import Vue from "vue";
import VueRouter from "vue-router";
import c from "./constant";

Vue.use(VueRouter);

const routes = [
   {
      path: "/dashboard",
      name: "Dashboard",
      component: () => import("./views/Main.vue"),
      meta: {
         require_permisos: [],
         title: "Dashboard",
         icon: "mdi-home",
         show: false,
      },
   },
   {
      path: "/",
      name: "Login",
      component: () => import("./views/Login.vue"),
      meta: {
         require_permisos: [],
         title: "Login",
         icon: "mdi-login",
         show: false,
      },
   },
   {
      path: "/state",
      name: "State",
      component: () => import("./views/State.vue"),
      meta: {
         require_permisos: [c.PERMISOS.super],
         title: "State",
         icon: "mdi-code-json",
         show: false,
      },
   },
   {
      path: "/accounts",
      name: "Accounts",
      component: () => import("./views/Accounts.vue"),
      meta: {
         require_permisos: [c.PERMISOS.consultar_cuentas],
         title: "Cuentas",
         icon: "mdi-account-cash",
         show: true,
      },
   },
   {
      path: "/movements",
      name: "Movements",
      component: () => import("./views/Movements.vue"),
      meta: {
         require_permisos: [c.PERMISOS.consultar_movimientos],
         title: "Movimientos",
         icon: "mdi-swap-horizontal",
         show: true,
      },
   },
   {
      path: "/settings",
      name: "Settings",
      component: () => import("./views/Settings.vue"),
      meta: {
         require_permisos: [c.PERMISOS.configuraciones],
         title: "Configuración",
         icon: "mdi-cogs",
         show: true,
      },
   },
   {
      path: "/binnacle",
      name: "Bitacora",
      component: () => import("./views/Bitacora.vue"),
      meta: {
         require_permisos: [c.PERMISOS.consultar_bitacora],
         title: "Bitácora",
         icon: "mdi-page-previous-outline",
         show: true,
      },
   },
   {
      path: "/comisiones",
      name: "Comisiones",
      component: () => import("./views/Comisiones.vue"),
      meta: {
         require_permisos: [c.PERMISOS.consultar_comisiones],
         title: "Comisiones",
         icon: "mdi-cash-refund",
         show: true,
      },
   },
   {
      path: "/stores",
      name: "Stores",
      component: () => import("./views/Stores.vue"),
      meta: {
         require_permisos: [c.PERMISOS.consultar_sucursales],
         title: "Sucursales",
         icon: "mdi-store",
         show: true,
      },
   },
   {
      path: "/users",
      name: "Users",
      component: () => import("./views/Users.vue"),
      meta: {
         require_permisos: [c.PERMISOS.consultar_usuarios],
         title: "Usuarios y Roles",
         icon: "mdi-account-group",
         show: true,
      },
   },
];

const router = new VueRouter({
   routes,
});

export default router;
