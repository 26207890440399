import Vue from "vue";
import "./plugins/axios";
import App from "./App.vue";
import Alert from "./components/Alert.vue";
import store from "./store";
import router from "./router";
import vuetify from "./plugins/vuetify";
import c from "./constant";
import JsonViewer from "vue-json-viewer";
import Print from 'vue-print-nb'
import './registerServiceWorker'

import Dialog from "./components/Dialog";
import Notification from "./components/Notification";

Vue.component("Dialog", Dialog);
Vue.component("Notification", Notification);
Vue.use(Print);
Vue.use(JsonViewer);
Vue.config.productionTip = false;

store.subscribe((mutation, state) => {
   localStorage.setItem("userdata", JSON.stringify(state.userdata));
});

const main = new Vue({
   store,
   router,
   vuetify,
   beforeCreate() {
      store.commit("initializeStore");
   },
   render: (h) => h(App),
}).$mount("#app");

const noti = new Vue({
   render: (h) => h(Alert),
}).$mount("#noti");

Vue.prototype.$noti = (msj) => {
   noti.$children[0].Show(msj);
};
Vue.prototype.$c = c;
Vue.prototype.$permiso = function (permiso, show = true) {
   let hasPermiso = false;
   if (store.state.userdata.permisos.includes(c.PERMISOS.super)) hasPermiso = true;
   if (permiso) {
      store.state.userdata.permisos.forEach((element) => {
         if (element == permiso) {
            hasPermiso = true;
         }
      });
   }
   if (!hasPermiso && show) {
      noti.$children[0].Show("Acceso Denegado");
   }
   return hasPermiso;
};

router.beforeEach(async function (to, from, next) {
   try {
      let authorized = false;
      if (to.meta.require_permisos.length > 0) {
         const permisos_usuario = store.state.userdata.permisos;
         if (permisos_usuario.length > 0) {
            const permisos_view = to.meta.require_permisos;
            const match_permisos = permisos_usuario.filter((elem) => permisos_view.includes(elem));
            if (match_permisos.length > 0 || permisos_usuario.includes(c.PERMISOS.super)) {
               authorized = true;
            } else {
               authorized = false;
            }
         } else {
            authorized = false;
         }
      } else {
         authorized = true;
      }
      if (authorized == true) {
         next();
      } else {
         router.push({
            name: "Login",
            query: {
               status: "denied",
               time: new Date().getTime(),
            },
         });
      }
   } catch (error) {
      next(error);
   }
});